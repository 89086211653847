import React from 'react';
import user from "../../assets/svg/userprofileicon.svg";
import share from "../../assets/svg/shareicon.svg";

const Sidebar = ({ onSelect,selectedSection }) => {
  
  return (
    <div className='flex border-r-indigo-500'>
      <div className="bg-white text-black w-64 p-5">
      <ul className='flex flex-col pt-20 items-center'>
          <li className="mb-5">
            <button
              onClick={() => onSelect('profile')}
              className={`w-full hover:bg-[#008CD2] hover:text-white text-[#999999]   py-1 px-12 font-bold inter_ff rounded ${
                selectedSection === 'profile' ? 'bg-[#008CD2] text-white' : ''
              }`}
            >
              Edit   Profile
            </button>
          </li>
          <li className="mb-5">
            <button
              onClick={() => onSelect('noti')}
              className={`w-full hover:bg-[#008CD2] hover:text-white text-[#999999]   py-1 px-12  font-bold inter_ff rounded ${
                selectedSection === 'noti' ? 'bg-[#008CD2] text-white' : ''
              }`}
            >
              Notifications
            </button>
          </li>
          <li className="mb-5">
            <button
              onClick={() => onSelect('plan')}
              className={`w-full hover:bg-[#008CD2] hover:text-white text-[#999999]   py-1 px-12 font-bold   inter_ff rounded ${
                selectedSection === 'plan' ? 'bg-[#008CD2] text-white' : ''
              }`}
            >
              Choose Plan
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;

// import React from 'react';
// import { Link } from 'react-router-dom';
// import { useNavigate } from 'react-router-dom';
// import user from "../../assets/svg/userprofileicon.svg"
// import share from "../../assets/svg/shareicon.svg"

// const Sidebar = () => {
//   const navigate = useNavigate();
//   const handleClick = () => {
//     // Redirect to the profile edit component without changing the URL
//     navigate('/user/profile', { replace: true });
// };
//   return (
//     <>
//  <div className='flex  border-r-indigo-500' >
//     <div className="bg-white text-black w-64  p-5">
//       <ul className='flex flex-col pt-20 items-center'>
//         <li className="mb-5">
//         <button
//             onClick={handleClick}
//           className="hover:bg-[#008CD2]  hover:text-white text-[#999999] font-bold  p-2 rounded"
//         >
//             Edit Profile
//         </button>
        
//         </li>
//         <li className="mb-5">
//           <Link to="/user/noti" className="hover:bg-[#008CD2]  hover:text-white text-[#999999] font-bold  p-2 rounded">Notifications</Link>
//         </li>
//         <li className="mb-5">
//           <Link to="/user/plan" className="hover:bg-[#008CD2]  hover:text-white text-[#999999] font-bold p-2 rounded">Choose Plan</Link>
//         </li>
//       </ul>
//     </div>
//  </div>
   
//     </>
//   );
// };

// export default Sidebar;
