import React, { useState, useEffect } from 'react';
import logo from "../assets/svg/logo.svg";
import icon from "../assets/svg/jicon.svg";
import { FaBars, FaTimes } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';

const Navbar = () => {
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [username, setUsername] = useState("");

  useEffect(() => {
    const username = localStorage.getItem('username');
    if (username) {
      setUsername(username);
    }
  }, [username]);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem("authToken");
    localStorage.removeItem('username');
    navigate('/login');
  };

  return (
    <nav className=" top-0 left-0 right-0">
      <div className="flex justify-between items-center px-4 py-4 sm:px-8 lg:px-12">
        <Link to="/">
          <img src={logo} alt="Logo" className="h-10" />
        </Link>

        <div className="flex items-center">
          <div className="hidden lg:flex xl:flex sm:flex md:flex space-x-6 items-center">
            <Link to="/faq" className="text-[#000000] hover:text-gray-800 text-[14px] sm:text-[16px] inter_ff font-normal">
              FAQ
            </Link>
            <Link to="/support" className="text-[#000000] hover:text-gray-800 text-[14px] sm:text-[16px] inter_ff font-normal">
              Support
            </Link>
            <div className="relative">
              <div
                className="flex items-center space-x-2 rounded-3xl border-2 p-1 cursor-pointer"
                onClick={toggleDropdown}
              >
                <img src={icon} alt="User Icon" className="w-6 sm:w-8" />
                <span className="text-[#000000] hover:text-gray-800 font-bold text-[12px] sm:text-[14px] pr-4 inter_ff">
                  {localStorage.getItem('username')}
                </span>
              </div>
              {dropdownOpen && (
                <div className="absolute right-0 mt-2 w-48 bg-white border border-gray-300 rounded-md shadow-lg z-10">
                  <Link to="/user">
                    <button className="block w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Profile</button>
                  </Link>
                  <button className="block w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Settings</button>
                  <button onClick={handleLogout} className="block w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                    Logout
                  </button>
                </div>
              )}
            </div>
          </div>
          <button onClick={toggleMenu} className="md:hidden   lg:hidden block xl:hidden sm:hidden text-gray-600 focus:outline-none">
            {menuOpen ? <FaTimes className="w-6 h-6" /> : <FaBars className="w-6 h-6" />}
          </button>
        </div>
      </div>

      {/* Mobile Menu */}
      <div className={`md:hidden  lg:hidden block xl:hidden sm:hidden ${menuOpen ? 'block' : 'hidden'} bg-white mb-4 rounded-xl shadow-md`}>
        <div className="flex flex-col items-center py-4 space-y-4">
          <Link to="/faq" className="text-[#000000] hover:text-gray-800 text-[16px] inter_ff font-normal">FAQ</Link>
          <Link to="/support" className="text-[#000000] hover:text-gray-800 text-[16px] inter_ff font-normal">Support</Link>
          <div className="relative">
            <div
              className="flex items-center space-x-2 cursor-pointer"
              onClick={toggleDropdown}
            >
              <img src={icon} alt="User Icon" className="w-6 sm:w-8" />
              <span className="text-[#000000] hover:text-gray-800 font-bold text-[16px] inter_ff">{username}</span>
            </div>
            {dropdownOpen && (
              <div className="absolute right-0 mt-2 w-48 bg-white border border-gray-300 rounded-md shadow-lg z-10">
                <Link to="/user">
                  <button className="block w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Profile</button>
                </Link>
                <button className="block w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Settings</button>
                <button onClick={handleLogout} className="block w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                  Logout
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
