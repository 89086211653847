// src/components/Dashboard.js
import React, { useState, useEffect } from 'react';

import axios from 'axios';
import Sidebar from './Sidebar';
import bgimg from "../../assets/images/Frame.jpg";
import { Outlet } from 'react-router-dom';
import Navbar from '../Layout/Navbar';
import Footer from '../Layout/Footer';
import cemera from "../../assets/svg/cemera.svg"
import icon from "../../assets/svg/profileheadicon.svg";
import user from "../../assets/svg/userprofileicon.svg";
import share from "../../assets/svg/shareicon.svg";
import { BASE_URL } from "../../../src/config";
import ProfileEdit from './ProfileEdit';
import NotificationsContent from './Notification';
import ChoosePlanContent from './Chooseplan';

const UserDashboard = () => {
  
  const [userName, setUserName] = useState('');
  const [email, setEmail] = useState('');
  const [selectedSection, setSelectedSection] = useState('profile');
  const [image, setImage] = useState(""); // Initialize image state
  const [imageUrl, setImageUrl] = useState('');
  useEffect(() => {
    const username = localStorage.getItem('username');
    const storedImageUrl = localStorage.getItem('userImage');
    if (username) {
      setUserName(username);
    }
    if (storedImageUrl) {
      setImageUrl(storedImageUrl);
    }
  }, []);

  
  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
        setImage(file);
        console.log("Selected file:", file);

        const formData = new FormData();
  formData.append('file', image);

        try {

            const authToken = localStorage.getItem('authToken');
            const response = await axios.post(`${BASE_URL}/api/updateProfile`,  formData, {

                headers: {
                    'Token': `${authToken}`,
                    'Content-Type': 'multipart/form-data',
                },
            });

            console.log("Image uploaded:", file);
            console.log(response.data);

            
            if (response.data.user) {
              // Update image URL and username in the state
              if (response.data.user.image) {
                const updatedImageUrl = `${response.data.user.image}?timestamp=${new Date().getTime()}`;
                setImageUrl(updatedImageUrl);
          localStorage.setItem('userImage', updatedImageUrl);
              }
              if (response.data.user.username) {
                  setUserName(response.data.user.username);
                  localStorage.setItem('username', response.data.user.username);
              }
          } else {
              console.error('User data not found in response');
          }
        } catch (error) {
            console.error('Error updating profile:', error.response?.data || error.message);
        }
    } else {
        console.log("No file selected");
    }
};


  useEffect(() => {
    // Fetch user data from localStorage
    const storedUserName = localStorage.getItem('userName');
    const storedEmail = localStorage.getItem('email');

    if (storedUserName && storedEmail) {
      setUserName(storedUserName);
      setEmail(storedEmail);
    }
  }, []);


  
  const renderContent = () => {
    switch (selectedSection) {
      case 'profile':
        return <ProfileEdit />;
      case 'noti':
        return <NotificationsContent />;
      case 'plan':
        return <ChoosePlanContent />;
      default:
        return <ProfileEdit />;
    }
  };

  return (
    <>
      <div className="min-h-screen flex flex-col" style={{
        backgroundImage: `url(${bgimg})`,
        backgroundSize: 'cover',
      }}>
        <Navbar />

        <div className="xl:mx-12 sm:mx-8 lg:mx-16 md:mx-12  mt-8 sm:mt-10 md:mt-12 mb-8 sm:mb-10 md:mb-12 rounded-xl shadow-sm bg-white">
          <div className="flex flex-col relative ">
            <p className="absolute w-full">
              <img src={icon} alt="" className="bg-[#008CD2] w-full" />
            </p>
            <div className="flex flex-col md:flex-row lg:flex-row xs:flex-row sm:flex-row relative xs:mt-8 md:mt-14 lg:mt-16 xl:mt-12 sm:mt-[4%] pl-5 sm:pl-6 ">
              <div className='flex xl:mt-12  w-full relative lg:mt-8'>
              <p className='grid lg:w-[14%] mt-[-10px] relative'>
  <input
    type="file"
    accept="image/*"
    onChange={handleImageChange}  // Connect the function here
    style={{ display: 'none' }}
    id="image-upload"
  />
  <div className='relative'>
    <img 
      src={imageUrl ? `${BASE_URL}/uploads/profile/${imageUrl}` : user} 
      alt="User profile" 
      className="object-cover h-[150px] w-[150px] rounded-full" 
    />
    <label htmlFor="image-upload">
      <img src={cemera} alt="" className='absolute top-[69%] left-[71%]' />
    </label>
  </div>
</p>

             
              <div className='flex w-full justify-between mt-8 items-center px-8'>
              <p>
              <p className='text-[21px] sx:text-[15px] font-bold inter_ff text-[#083A50]'>{userName}</p>
              <p className='text-[17px] font-bold sx:text-[14px] text-[#808080] inter_ff'>Your account is ready, you can now apply for advice.</p>
              </p>
              <p><img src={share} alt="" /></p>
              </div>
              </div>
             
            </div>
            <div className="xs:flex flex xs:flex-col  sm:flex-row mb-4">
            <div className="sm:w-1/4">
            <Sidebar onSelect={setSelectedSection} selectedSection={selectedSection}/>
            </div>
              <div className="p-2 sm:p-5 m-2 sm:m-4 w-full">
              {renderContent()}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default UserDashboard;

// // src/components/Dashboard.js
// import React from 'react';
// import Sidebar from './Sidebar';
// import bgimg from "../../assets/images/Frame.jpg";
// import { Outlet } from 'react-router-dom';
// import Navbar from '../Layout/Navbar';
// import Footer from '../Layout/Footer';
// import icon from "../../assets/svg/profileheadicon.svg"
// import user from "../../assets/svg/userprofileicon.svg"
// import share from "../../assets/svg/shareicon.svg"
// const UserDashboard = () => {
//   return (
//     <>
   
//     <div className='min-h-screen flex flex-col' style={{
//       backgroundImage: `url(${bgimg})`,
//       backgroundSize: 'cover',
//     }}>
//       <Navbar />

//       <div className='mx-12  mt-12 mb-12  rounded-xl shadow-lg   bg-white'>
//         <div className="flex flex-col  relative">
//           <p className='absolute'><img src={icon} alt="" className='bg-[#008CD2] w-screen ' /></p>
//           <div className='flex relative  mt-[6%] pl-20'>
//             <p>
//               <img src={user} alt="" className=' ' /></p>


//           </div>
//           <div className='flex mb-4  '>
//             <div className='flex'>
//               <Sidebar />
//             </div>

//             <div className="p-5 m w-full">
//               <Outlet />
//             </div>
//           </div>

        
//         </div>
//       </div>
//     </div>
//       <Footer />
//       </>
//   );
// };

// export default UserDashboard;
