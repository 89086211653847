import React, { useState,useEffect } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from "../../assets/svg/logo.svg";
import { Link, useNavigate } from 'react-router-dom';
import signinimg from '../../assets/images/image.png';
import icon2 from '../../assets/svg/otpicon.svg';
import signincircle from '../../assets/images/otpcircle.png';
import { BASE_URL } from "../../config.js";

const Otp = () => {
  const navigate = useNavigate();
  const [otp, setOtp] = useState(['', '', '', '']); // State for 4 OTP digits
  const [email, setEmail] = useState('');

  useEffect(() => {
    const storedEmail = localStorage.getItem('email');
    if (storedEmail) {
      setEmail(storedEmail);
    }
  }, []);
  const handleChange = (e, index) => {
    const value = e.target.value;
    if (/^[0-9]$/.test(value) || value === '') {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      // Move to next input if the current one is filled
      if (value && index < 3) {
        document.getElementById(`otp-input-${index + 1}`).focus();
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const otpCode = otp.join(''); // Combine the OTP digits
  
    try {
      const response = await axios.post(`${BASE_URL}/api/verifyOtp`, { otp: otpCode, email});
      console.log('API Response:', response.data);
      if (response.data?.status === "success") {
        toast.success('Sign-in successful!');
        const authToken = response.data.token;
        const username = response.data.user.name; 
        console.log(response.data.user)
        if (authToken) {
          localStorage.setItem('authToken', authToken);
          localStorage.setItem('username', username);
          navigate('/home'); 
        } else {
          console.error('Auth token not found in response');
          return; // Add return statement here
        }
      } else {
        toast.error(response?.data?.msg || 'Failed to verify OTP. Please try again.');
      }
    } catch (error) {
      toast.error('Failed to verify OTP. Please try again.');
    }
  };

  return (
    <div className="flex min-h-screen overflow-hidden relative z-[234]">
      <div className="flex-1 xs:hidden sm:hidden  flex justify-between flex-col lg:flex bg-cover bg-center"
        style={{ backgroundImage: `url(${signinimg})` }}>
        <div>
          <h1 className=''> <Link to="/"><img src={logo} alt="EchoWrite Logo" /></Link> </h1>
        </div>
        <div className="flex justify-center  pt-[1%] mb-12 xs:p-0">
          <div className="bg-opacity-70 backdrop-blur-xl py-8 pl-4 pr-24 max-w-lg max-h-72 p-4 rounded-xl">
            <button className='flex gap-3 my-3 text-white rounded-3xl bg-[#008CD2] py-2 px-4'><img src={icon2} alt="Icon" />
              Top Notch Stock Resources
            </button>
            <p className="text-[20px] text-white font-normal pt-4 mb-2">Today, we create innovative solutions to the <br className='hidden lg:block'/> challenges that consumers face in both their <br className='hidden lg:block'/> everyday lives and events.</p>
          </div>
        </div>
        <div className='absolute lg:block hidden bottom-[-140px] right-[430px] opacity-75'>
          <img src={signincircle} alt="Circle" />
        </div>
      </div>

      <div className="flex-1 flex flex-col items-center xs:items-center xs:justify-center justify-center bg-white p-8">
        <div className="w-full max-w-md relative flex flex-col items-center p-8 rounded-lg">
          <h2 className="text-[23px] font-semibold text-black mb-2 text-center">OTP Verification</h2>
          <p className="mb-6 text-center text-[15px] font-normal text-[#7E7E7E]">Please enter the OTP received at your email: {email}</p>
          <form className='w-full' onSubmit={handleSubmit}>
            <div className="flex flex-col justify-center gap-2 mb-4">
              <div className="flex justify-center gap-2 mb-4">

              {otp.map((digit, index) => (
                <input
                key={index}
                id={`otp-input-${index}`}
                type="text"
                maxLength="1"
                value={digit}
                onChange={(e) => handleChange(e, index)}
                className="w-12 h-12 text-center text-2xl border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                />
              ))}
              </div>
              <div className='flex justify-center'>

              <button
              type="submit"
              className="lg:px-24 px-28 bg-blue-500  text-white py-2 rounded-3xl hover:bg-blue-600 transition"
              >
              Continue
            </button>
              </div>
            </div>
            
          </form>
        </div>
        <ToastContainer position="top-center" autoClose={3000} />
      </div>
    </div>
  );
};

export default Otp;
